@import './global-styles.css';

body {
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
}

.data-table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Inter', sans-serif;
    margin: 10px 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.data-table thead {
    background-color: var(--secondary-color);
}

.data-table th,
.data-table td {
    padding: 4px 4px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
}

.data-table th {
    background-color: var(--primary-color);
    color: var(--background-color);
    cursor: pointer;
    text-align: left;
    padding: 12px 15px;
    user-select: none;
    transition: background-color 0.3s ease;
}

.data-table th:hover {
    background-color: #5a5a8b; /* Slightly lighter purple for hover */
}

.data-table tbody tr:nth-of-type(even) {
    background-color: var(--light-background);
}

.data-table tbody tr:last-of-type {
    border-bottom: 2px solid var(--primary-color);
}

.table-row {
    transition: background-color 0.3s;
}

/* Style for sort indicators */
.data-table th span {
    margin-left: 8px;
    font-size: 0.9em;
}

/* Icon button styling */
.icon-button {
    background-color: transparent;
    border-color: transparent;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 1rem;
}

.icon-button .fas {
    font-size: 1rem;
    color: var(--primary-color);
}

.icon-button .fas:hover {
    color: #ea3eea; /* Change icon color on hover */
}

/* Bulk actions container */
.bulk-actions {
    margin-bottom: 15px;
    text-align: left;
}

/* Bulk delete button */
.btn-danger {
    background-color: #dc3545;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.btn-danger:hover {
    background-color: #c82333;
}

.btn-danger:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed;
}

.checkbox-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.data-table td:first-child,
.data-table th:first-child {
    width: 65px;
    max-width: 65px;
    box-sizing: border-box;
    padding: 0;
}

/* Test Checkbox Container */
.test-checkbox-container {
    margin-bottom: 20px;
    font-size: 16px;
}

.test-checkbox-container label {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Ensure checkbox is visible and properly sized within table cells */
.data-table .MuiCheckbox-root {
    padding: 0;
}

.data-table .MuiCheckbox-root .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
}

/* Additional styles for Material-UI Checkbox */
.MuiCheckbox-root {
    color: var(--primary-color) !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: var(--primary-color) !important;
}

.header-cell {
    cursor: move;
    user-select: none;
    transition: opacity 0.2s;
}

.header-cell:hover {
    background-color: var(--secondary-color);
}

.header-cell.dragging {
    opacity: 0.5;
    background-color: var(--primary-color);
}
