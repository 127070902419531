.syllabus-viewer {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: auto;
}

.toc-container {
    margin-bottom: 20px;
}

.toc-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
}

.toc-item {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.toc-item:hover {
    background-color: #e0e0e0;
    transform: scale(1.05);
}

.data-container {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f7f7f7;
}

.error-message {
    color: red;
    font-weight: bold;
}

.loading-message {
    font-style: italic;
}

.hierarchy-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.hierarchy-list li {
    margin-bottom: 15px;
}

.syllabus-table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
}

.syllabus-table td {
    border: 1px solid #ccc;
    padding: 5px;
    text-align: left;
}

.category-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.category-buttons button {
    padding: 10px 20px;
    border: 2px solid #007bff;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
}

.category-buttons button:hover {
    background-color: #0056b3;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
}

.category-buttons button.active {
    background-color: #28a745; /* Highlight the active button */
    border-color: #28a745;
    color: white;
}

.hierarchy-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.hierarchy-list li {
    margin: 5px 0;
}

.hierarchy-header h4 {
    margin: 5px 0;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.hierarchy-content {
    margin-left: 20px;
}

.hierarchy-header:hover {
    text-decoration: underline;
}

.accordion-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between items */
}

.accordion-item {
    border: 1px solid #ccc;
    border-radius: 6px;
    overflow: hidden;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: #f7f7f7;
    font-weight: bold;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s ease;
}

.accordion-header:hover {
    background-color: #e7e7e7;
}

.accordion-content {
    padding: 15px 20px;
    background-color: #ffffff;
    font-size: 14px;
    color: #333;
    transition: max-height 0.3s ease;
}

.accordion-item.expanded .accordion-header {
    background-color: #e7e7e7;
}
