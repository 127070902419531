@import './global-styles.css';

body {
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
}

.navbar {
    background-color: var(--primary-color);
    padding: 10px;
}

.navbar-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.navbar-left {
    display: flex;
    align-items: center;
}

.navbar-right {
    display: flex;
    align-items: center;
    justify-self: flex-end;
}

.navbar-center {
    text-align: center;
    flex: 1;
}

.navbar-logo-image {
    height: 40px;
}

.navbar-title {
    color: var(--background-color);
    font-size: 18px;
    margin-left: 10px;
    font-weight: bold;
}

.navbar-tagline {
    color: #fff;
    font-size: 14px;
    font-weight: lighter;
    font-style: italic;
}

.navbar-menu {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
}

.navbar-item {
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.navbar-link, .navbar-button {
    color: #fff;
    text-decoration: none;
    padding: 10px 15px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 14px;
    font-family: inherit;
    display: flex;
    align-items: center;
    height: 40px;
    transition: background-color 0.3s ease, color 0.3s ease; /* Added transition for smooth hover effect */
}

.navbar-link:hover, .navbar-button:hover {
    background-color: var(--secondary-color);
    border-radius: 16px;
}

.navbar-link.active {
    background-color: var(--accent-color); /* Gold color for the active link */
    color: var(--primary-color); /* Deep purple for the text */
    border-radius: 16px;
    font-weight: bold;
}

.theme-title {
    color: var(--background-color);
    margin-right: 8px;
    font-size: smaller;
}

.theme-selector {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: var(--background-color);
    font-size: 12px;
    margin-right: 20px;
}

.navbar-user {
    color: var(--background-color);
    font-weight: bold;
    font-size: 20px;
}
