@import './global-styles.css';

/* Keyframes for ease-in animation */
@keyframes easeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.login-page {
    display: flex;
    justify-content: center;
    align-items: self-start;
    height: 100vh;
    background-color: #f0f0f0;
    animation: easeIn 0.5s ease-in-out; /* Apply the animation */
}

.login-container {
    margin: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 30px; /* Adjusted padding for a smaller box */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px; /* Limit the width of the container */
    width: 100%;
    position: relative; /* Added position relative to position the mascot */
    animation: easeIn 0.5s ease-in-out; /* Apply the animation */
}

.mascot-login {
    position: absolute;
    top: -32px;
    left: -40px;
    width: 120px;
    height: auto;
}

.login-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: var(--text-color);
    text-align: center;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.login-input {
    height: 40px;
    border: 1px solid var(--border-color);
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    background-color: var(--background-color);
    border-radius: 4px;
    font-size: 16px;
}

.login-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: var(--accent-color);
}

.login-link {
    margin-top: 20px;
    text-decoration: none;
    color: var(--text-color);
    font-size: 14px;
    transition: color 0.3s ease;
}

.login-link:hover {
    color: var(--primary-color);
}

.login-message {
    margin-top: 20px;
    color: var(--text-color);
    font-size: 14px;
    text-align: center;
}
.platform-toggle {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.platform-toggle label {
    margin-right: 15px;
}

.platform-toggle input {
    margin-right: 5px;
}