@import './global-styles.css';

body {
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
}

.reports-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: var(--background-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.reports-content {
    display: flex;
    /*justify-content: space-between;*/
}

.reports-controls {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

.
.report-options label {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.reports-controls select,
.reports-controls input,reports-controls label,
.report-options select,
.report-options input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    max-width: 300px;
}

.reports-controls button {
    padding: 10px 20px;
    font-weight: bold;
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: center;
}

.reports-controls button:hover {
    background-color: var(--accent-color);
}

.reports-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 0.7;
}

.reports-controls option:disabled {
    color: #666;
    font-style: italic;
}

.report-data {
    width: 65%;
    margin: 20px;
    background-color: var(--light-background);
    padding: 40px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.student-list ul {
    list-style-type: none;
    padding: 0;
    width: 90%;
}

.student-list li {
    padding: 4px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.student-list li.selected,
.student-list li:hover {
    background-color: var(--light-background);
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 260px;
    width: 100%;
}

.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.copy-button {
    width: 100%;
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.copy-button:hover {
    background-color: var(--accent-color); /* Lighter shade on hover */
    color: var(--text-color)
}

.copy-button:active {
    background-color: var(--primary-color); /* Darker shade when clicked */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
        scale: 1.03;
    }
    to {
        opacity: 1;
        transform: translateY(0);
        scale: 1;
    }
}

.fade-in {
    animation: fadeIn 0.3s ease-in;
}


.custom-query-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

.custom-query-input {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 10px;
}

.query-input {
    flex: 1;
    max-width: 500px;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 14px;
}

.custom-query-input button {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    white-space: nowrap;
}

.custom-query-input button.selected {
    background-color: var(--accent-color);
}

.custom-query-input button:hover {
    background-color: var(--secondary-color);
}

.options-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.options-container button {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.options-container button.selected {
    background-color: var(--accent-color);
}

.options-container button:hover {
    background-color: var(--secondary-color);
}

.copy-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.copy-button:hover {
    background-color: var(--secondary-color);
}


.css-1pahdxg-control {
    border-color: #ddd !important;
}

.css-1okebmr-indicatorSeparator {
    display: none;
}


.inline-label {
    display: inline-flex;
    align-items: center;
}

.term-sub_text {
    font-size: 10px;
    display: inline;
}

.select-button {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: middle;
}

.select-button option:checked {
    background-color: var(--primary-color);
    color: white;
}
