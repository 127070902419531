.feedback-button {
    position: fixed;
    bottom: 20px;
    z-index: 1000;
    width: 148px;
    height: 30px;
    background-color: var(--secondary-color);
    color: var(--background-color);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.1s ease;
}

.feedback-button:hover {
    background-color: var(--accent-color);
}

.feedback-button:active {
    transform: translateY(2px);
}

.feedback-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.feedback-modal-content {
    position: relative;
    width: 90%;
    max-width: 500px;
    background-color: var(--background-color);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    animation: fadeUp 0.3s ease-in-out;
    color: var(--text-color);
}

/* Animation for modal */
@keyframes fadeUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Styles for the input fields and textarea */
.feedback-modal-content input,
.feedback-modal-content textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 10px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--light-background);
    color: var(--text-color);
    font-size: 16px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.feedback-modal-content input:focus,
.feedback-modal-content textarea:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(var(--primary-color), 0.2);
}

/* Error message styling */
.feedback-error {
    background-color: #ffe6e6;
    border-left: 4px solid #ff4d4d;
    color: #cc0000;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
}

/* Modal button styles */
.feedback-modal-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.feedback-modal-buttons button {
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;
}

.feedback-modal-buttons .cancel-button {
    background-color: #fc6c6c;
    color: white;
}

.feedback-modal-buttons .cancel-button:hover {
    background-color: var(--accent-color); /* Adjust as needed */
}

.feedback-modal-buttons .submit-button {
    background-color: #4C956CFF;
    color: var(--background-color);
}

.feedback-modal-buttons .submit-button:hover {
    background-color: var(--accent-color);
}


.feedback-type-toggle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.feedback-type-toggle label {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-background);

    border-radius: 8px;
    padding: 10px;
    margin: 0 5px; /* Space between the buttons */
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
}

.feedback-type-toggle input {
    display: none; /* Hide the original radio input */
}

.feedback-type-toggle label:hover {
    background-color: var(--accent-color); /* Optional: Add a hover effect */
}

.feedback-type-toggle label.active {
    background-color: var(--secondary-color);

    color: var(--background-color); /* Text color when active */
}

/* Slightly dim unselected buttons */
.feedback-type-toggle label:not(.active) {
    opacity: 0.7; /* Reduced opacity for non-selected labels */
}
.leave-feedback-text {
    color: var(--primary-color);
    font-size: 36px;
    font-weight: bolder;
}
.rating-slider {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

/* Slider label */
.slider-label {
    font-weight: bold;
    margin-bottom: 5px;
}

/* Container for the slider value labels and the slider itself */
.slider-value-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Styling for the individual slider value labels */
.slider-value {
    flex: 1;
    text-align: center;
    font-size: 12px;
    color: var(--text-color);
}

/* Styling for the range input (slider) */
.feedback-slider {
    width: 100%;
    margin: 0 10px;
    cursor: pointer;
    -webkit-appearance: none; /* Override default styles */
    appearance: none;
    height: 8px;
    background: var(--secondary-color);
    border-radius: 5px;
    outline: none;
    transition: background 0.3s ease;
}

/* Styling for the slider thumb */
.feedback-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: var(--secondary-color);
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.feedback-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: var(--secondary-color);
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

/* Indicator for the current slider value */
.slider-indicator {
    text-align: center;
    margin-top: 5px;
    font-size: 14px;
    color: var(--text-color);
}
