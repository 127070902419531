.custom-modal {
    max-width: 600px;
    border-radius: 10px;
    background-color: transparent;
}

.custom-modal-header {
    background-color: var(--secondary-color);
    color: var(--background-color);
    border-bottom: 1px solid var(--border-color);
    padding: 20px;
}

.custom-modal-body {
    background-color: var(--background-color);
    padding: 20px;
}

.custom-modal-footer {
    background-color: var(--secondary-color);
    border-top: 1px solid var(--border-color);
    padding: 15px;
}

.custom-input, .custom-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.custom-input:focus, .custom-textarea:focus {
    outline: none;
    border-color: var(--primary-color);
}

.custom-cancel-button, .custom-save-button {
    background-color: var(--primary-color);
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 14px;
    color: var(--background-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-cancel-button:hover, .custom-save-button:hover {
    background-color: var(--accent-color);
}

.custom-cancel-button:disabled, .custom-save-button:disabled {
    background-color: var(--border-color);
    cursor: not-allowed;
}

.form-label {
    font-weight: bold;
    color: var(--primary-color);
}
