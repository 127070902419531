.newsletter-editor {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    background-color: var(--background-color);
}

.editor-wrapper {
    width: 900px;
    margin: 20px auto;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.tox-tinymce {
    flex: 1 !important;
    min-height: calc(100vh - 300px) !important;
}

.editor-header {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
    padding: 10px;
    background-color: var(--secondary-color);
}

.template-selection {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 10px;
}

.version-control {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 4px;
    background: var(--light-background);
    border-radius: 4px;
}

.version-control input,
.version-control select {
    height: 24px;
    padding: 0 6px;
    font-size: 0.9em;
}

.tag-selection {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    padding: 4px;
    background: var(--light-background);
    border-radius: 4px;
}

.new-button {
    margin: 4px;
    padding: 4px 8px;
    font-size: 0.9em;
}

.sending-mode-controls {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 4px;
    background: var(--light-background);
    border-radius: 4px;
}

.mode-selection {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    font-size: 0.9em;
}

.mode-selection label {
    display: flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
    cursor: pointer;
}

.direct-email-input {
    width: 180px;
    height: 24px;
    padding: 0 6px;
    font-size: 0.9em;
}

.tag-selection button {
    padding: 2px 6px;
    font-size: 0.85em;
}
.direct-email-input {
    width: 200px;
    height: 30px;
    padding: 0 8px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    transition: border-color 0.2s;
}
.tag-selection button {
    padding: 4px 8px;
    font-size: 0.9em;
}

.tag-selection button.selected {
    background-color: var(--accent-color); /* Use var for selected background color */
    color: var(--text-color); /* Use var for light text color */
}

.tag-selection input {
    height: 30px;
    padding: 0 10px;
}

.selected-tags {
    padding: 5px 10px;
    background-color: var(--light-background); /* Use var for light background */
    border-bottom: 1px solid var(--border-color); /* Use var for border color */
}

.editor-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 10px;
    background-color: var(--secondary-color); /* Use var for footer background */
}

.editor-footer button {
    padding: 5px 15px;
    background-color: var(--light-background); /* Use var for primary button color */
    color: var(--text-color); /* Use var for light text color */
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s; /* Add smooth transition */
}

.editor-footer button:hover {
    background-color: var(--primary-color); /* Use var for hover state */
}

.preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 900;
}

.preview-content {
    background-color: var(--background-color); /* Use var for preview content background */
    padding: 20px;
    border-radius: 5px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    z-index: 1000;
    display: flex; /* Ensures the content inside is displayed in a column */
    flex-direction: column; /* Set direction to column */
    align-items: flex-start; /* Align content to start, adjust as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    background-color: var(--danger-color); /* Use var for close button color */
    color: var(--text-color); /* Use var for light text color */
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition */
}

.close-button:hover {
    background-color: var(--accent-color); /* Use var for hover state */
}

/* Ensure consistent button style inside preview content */
.preview-content .button {
    padding: 10px 20px;
    background-color: var(--primary-color); /* Use var for button color */
    color: var(--text-color); /* Use var for light text color */
    text-decoration: none;
    border-radius: 5px;
    margin: 10px 0;
    cursor: pointer;
    transition: background-color 0.3s;
}

.preview-content .button:hover {
    background-color: var(--primary-color); /* Use var for hover state */
}

.newsletter-editor .btn {
    background-color: var(--primary-color) !important; /* Use var for bootstrap override */
    color: var(--text-color) !important; /* Use var for light text */
}

/* Avoiding Bootstrap container styles */
.newsletter-editor .container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    background-color: var(--background-color); /* Use var for container background */
    border-radius: 10px;
    padding: 20px;
}

/* Avoid Bootstrap padding and margin conflicts */
.newsletter-editor .content {
    padding: 15px !important; /* Override with !important if needed */
    margin: 10px 0 !important;
}

.version-select {
}
