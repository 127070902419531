.chat-container {
  position: fixed;
  top: 64px; /* Height of NavBar */
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: #ffffff;
  overflow: hidden;
  padding-bottom: env(safe-area-inset-bottom);
}

.chat-container .MuiGrid-container {
  height: 100%;
  margin: 0;
  width: 100%;
}

.chat-container .MuiGrid-item:first-of-type {
  border-right: 1px solid #e5e7eb;
  padding: 0;
  background-color: #ffffff;
  width: 340px !important;
  max-width: 340px;
  height: 100%;
  overflow: hidden;
  display: flex;
}

.chat-container .MuiGrid-item:last-of-type {
  padding: 0;
  flex-grow: 1;
  background-color: #ffffff;
  height: 100%;
  overflow: hidden;
  display: flex;
}

.chat-paper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
  height: 100%;
}

.chat-header {
  padding: 1rem 1.5rem;
  background: #ffffff;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 64px; /* Match navbar height */
  left: 340px; /* Match sidebar width */
  right: 0;
  z-index: 20;
}

.chat-header h6 {
  color: #111827;
  font-weight: 600;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.chat-header h6::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #22c55e;
  border-radius: 50%;
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  padding-top: calc(1rem + 64px); /* Extra padding for navbar */
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  gap: 0.25rem; /* Tighter spacing between messages */
  margin-bottom: 0;
  height: calc(100vh - 64px - 140px); /* viewport height - navbar - input area */
  min-height: 200px;
  scroll-padding-top: 64px; /* Match navbar height */
  background-color: #ffffff;
}

.message-container {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  max-width: 85%;
  padding: 0 0.5rem;
}

.message-container.user {
  margin-left: auto;
  flex-direction: row-reverse;
}

.message {
  padding: 1rem 1.25rem;
  position: relative;
  max-width: 85%;
  border-radius: 1.75rem;
  line-height: 1.5;
}

@keyframes messageAppear {
  from {
    opacity: 0;
    transform: translateY(8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.message-container {
  animation: messageAppear 0.2s ease forwards;
  display: flex;
  align-items: flex-end;
  padding: 0.375rem 1.25rem;
  position: relative;
  max-width: 100%;
}

.message {
  padding: 0.75rem 1rem;
  position: relative;
  max-width: 85%;
  border-radius: 1rem;
  line-height: 1.35;
  font-size: 0.875rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.message.bot {
  background-color: #e9ecef;
  color: #000000;
  margin-right: auto;
  border-bottom-left-radius: 0.5rem;
  position: relative;
}

.message.bot::before {
  content: "";
  position: absolute;
  left: -0.75rem;
  bottom: 0;
  width: 1rem;
  height: 1.25rem;
  background: radial-gradient(circle at top left, transparent 0.75rem, #e9ecef 0);
}

.message.user {
  background-color: var(--secondary-color);
  color: #ffffff;
  margin-left: auto;
  border-bottom-right-radius: 0.5rem;
  position: relative;
}

.message.user::before {
  content: "";
  position: absolute;
  right: -0.75rem;
  bottom: 0;
  width: 1rem;
  height: 1.25rem;
  background: radial-gradient(circle at top right, transparent 0.75rem, var(--secondary-color) 0);
}

.message-container.bot {
  padding-left: 3rem;
}

.message-container.user {
  padding-right: 1rem;
}

/* Hide the icon by default but keep its space */
.source-icon {
  opacity: 0;
  position: absolute;
  left: 0.75rem;
  bottom: 0.5rem;
  transition: opacity 0.2s ease;
}

/* Show icon on hover */
.message-container:hover .source-icon {
  opacity: 1;
}

.source-icon {
  font-size: 1.25rem;
  margin-top: 0.25rem;
}

.source-icon.curriculum {
  color: #2e7d32;  /* Green */
}

.source-icon.general {
  color: #ed6c02;  /* Orange */
}

/* Custom tooltip styles */
.custom-tooltip {
  background-color: #1a1a1a !important;
  max-width: 350px !important;
  padding: 0 !important;
}

.source-tooltip {
  padding: 0.75rem;
}

.source-header {
  display: block;
  color: #9ca3af !important;
  margin-bottom: 0.5rem !important;
}

.source-header.warning {
  color: #ed6c02 !important;
}

.source-item {
  background-color: rgba(255, 255, 255, 0.05) !important;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.source-main {
  color: #4caf50 !important;
  margin-bottom: 0.25rem;
}

.source-path {
  color: #9ca3af !important;
  display: block;
  line-height: 1.4;
}

.source-path-end {
  font-weight: 700 !important;
  color: #b3e5fc !important;
}

/* Markdown content styles */
.markdown-content {
  line-height: 1.5;
}

.markdown-content p {
  margin-bottom: 0.5rem;
}

.markdown-content ul,
.markdown-content ol {
  margin-left: 1.5rem;
  margin-bottom: 0.5rem;
}

.markdown-content li {
  margin-bottom: 0.25rem;
}

.markdown-content code {
  background-color: rgba(0, 0, 0, 0.04);
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 0.9em;
  font-family: ui-monospace, monospace;
}

.input-container {
  display: flex;
  gap: 0.75rem;
  padding: 1rem;
  background: #ffffff;
  position: fixed;
  bottom: 1rem;
  left: calc(340px + (100% - 340px) / 2); /* sidebar width + half of remaining space */
  transform: translateX(-50%);
  max-width: 48rem;
  width: calc(100% - 340px - 4rem); /* full width minus sidebar minus padding */
  z-index: 10;
  box-sizing: border-box;
}

.input-container form {
  display: flex;
  gap: 0.75rem;
  width: 100%;
  padding: 0.4rem;
  background-color: #f2f2f7;
  border-radius: 1.25rem;
  border: 1px solid #e5e7eb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 0.875rem;
}

.input-container .MuiTextField-root {
  background-color: transparent;
}

.input-container .MuiTextField-root .MuiOutlinedInput-root {
  background-color: transparent;
  border-radius: 0;
  border: none;
  box-shadow: none;
}

.input-container .MuiTextField-root .MuiOutlinedInput-root:hover {
  border: none;
}

.input-container .MuiTextField-root .MuiOutlinedInput-root.Mui-focused {
  border: none;
}

.input-container .MuiTextField-root .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: none;
}

.send-button {
  padding: 0.4rem !important;
  color: var(--secondary-color) !important;
  border-radius: 0.5rem !important;
  transition: all 0.2s ease !important;
  min-width: 32px !important;
  width: 32px !important;
  height: 32px !important;
}

.send-button:hover:not(:disabled) {
  color: var(--secondary-color) !important;
  background-color: #e5e7eb !important;
}

.send-button:disabled {
  color: #9ca3af !important;
  background-color: transparent !important;
}

.send-button svg {
  font-size: 1.125rem;
}