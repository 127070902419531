@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

/*:root {*/
/*    --primary-color: #6A0DAD; !* Deep purple *!*/
/*    --secondary-color: #B39DDB; !* Lavender *!*/
/*    --accent-color: #FFD700; !* Gold *!*/
/*    --text-color: #2E2E2E; !* Dark gray *!*/
/*    --background-color: #FFFFFF;*/
/*    --light-background: #F3E5F5; !* Very light purple *!*/
/*    --success-color: #66BB6A; !* Green *!*/
/*    --danger-color: #dc3545; !* Red *!*/
/*    --border-color: #ddd;*/
/*}*/

/* Cool Ocean Breeze */
/*:root {*/
/*    --primary-color: #0077B6; !* Deep Blue *!*/
/*    --secondary-color: #00B4D8; !* Light Blue *!*/
/*    --accent-color: #90E0EF; !* Sky Blue *!*/
/*    --text-color: #023E8A; !* Dark Blue *!*/
/*    --background-color: #FFFFFF; !* White *!*/
/*    --light-background: #CAF0F8; !* Very Light Blue *!*/
/*    --success-color: #76C893; !* Sea Green *!*/
/*    --danger-color: #d00000; !* Red *!*/
/*    --border-color: #ddd; !* Light Gray *!*/
/*}*/

/* Cool Forrest Dream */
:root {
    --primary-color: #2C6E49; /* Forest Green */
    --secondary-color: #4C956C; /* Light Green */
    --accent-color: #81C784; /* Mint Green */
    --text-color: #1B4332; /* Dark Green */
    --background-color: #FFFFFF; /* White */
    --light-background: #D8F3DC; /* Very Light Green */
    --success-color: rgba(106, 153, 78, 0.49); /* Olive Green */
    --danger-color: #E63946; /* Red */
    --border-color: #ddd; /* Light Gray */
}
