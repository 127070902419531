@import './global-styles.css';

body {
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
}

.container {
    display: flex;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    animation: fadeUp 0.3s ease-in-out; /* Add fade-up animation */
}

.sidebar {
    flex: 1;
    padding: 8px;
    background-color: var(--background-color);
    margin-right: 20px;
    border: 1px solid var(--border-color);
}

.main-content {
    flex: 2;
    min-height: 60vh;
    padding: 16px;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 8px;
}

.header {
    text-align: center;
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.class-list {
    list-style-type: none;
    padding: 0;
}

.no-classes-message {
    padding: 1rem;
    color: #666;
    text-align: center;
    font-style: italic;
    background: var(--light-background);
    border-radius: 4px;
    margin: 1rem;
}

.class-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    margin: 4px;
    border-radius: 4px;
    background-color: var(--light-background);
    cursor: pointer;
    color: inherit;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.class-item-edit {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 4px;
    background-color: var(--light-background);
    cursor: pointer;
    color: inherit;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.class-item.selected {
    background-color: var(--accent-color);
    color: black;
}

.class-item:hover {
    background-color: var(--border-color);
    color: black;
}

.button {
    padding: 4px;
    margin: 4px;
    min-width: 120px;
    border: none;
    border-radius: 4px;
    background-color: var(--primary-color);
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: 500;
}

.button.primary {
    background-color: var(--success-color);
}

.button.danger {
    display: flex;
    background-color: var(--danger-color);
    height: 20px;
    justify-content: center;
    align-items: center;
}

.button:hover {
    background-color: #5a5a8b;
}

.button.primary:hover {
    background-color: #1d6219;
}

.button.danger:hover {
    background-color: #c82333;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    border-radius: 4px;
    background-color: #d9d9d9;
    margin-top: 20px;
}

.input {
    padding: 10px;
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
    margin-bottom: 10px;
}

.input.compact {
    padding: 5px;
    font-size: 12px;
    margin-bottom: 4px;
}

.textarea {
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
    margin-bottom: 10px;
}

.classManager-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.classManager-modal-content {
    background: white;
    color: black;
    padding: 20px;
    border-radius: 4px;
    width: 500px;
}

.classManager-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    border-radius: 4px;
    background-color: #d9d9d9;
    margin-top: 20px;
}

.classManager-input {
    padding: 10px;
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
    margin-bottom: 10px;
}

.classManager-textarea {
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
    margin-bottom: 10px;
}

.classManager-button {
    padding: 4px;
    margin: 4px;
    min-width: 120px;
    border: none;
    border-radius: 4px;
    background-color: var(--primary-color);
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: 500;
}

.classManager-button-primary {
    background-color: var(--success-color);
}

.classManager-button-danger {
    background-color: var(--danger-color);
}

.classManager-class-list {
    list-style-type: none;
    padding: 0;
}

.classManager-class-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    margin: 4px;
    border-radius: 4px;
    background-color: var(--light-background);
    cursor: pointer;
    color: inherit;
    transition: background-color 0.3s ease, color 0.3s ease;
}
/* Fade-up animation */
@keyframes fadeUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-up {
    animation: fadeUp 0.3s ease-in-out;
}
