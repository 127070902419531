@import './global-styles.css';

body {
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
}

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-inline: 10px;
    background-color: var(--light-background);
    animation: fadeUp 0.3s ease-in-out; /* Add fade-up animation */
}

.no-data-message {
    padding: 2rem;
    text-align: center;
    color: #666;
    background: var(--background-color);
    border-radius: 8px;
    margin: 1rem;
    font-style: italic;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
}

.navigation-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: var(--secondary-color);
    padding-inline: 10px;
    border-radius: 4px;
}

.navigation-controls button,
.year-selector select,
.class-selector select,
.export-button,
.role-selector button {
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    padding: 8px 12px;
    margin: 2px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}

.navigation-controls button:hover,
.class-selector select:hover,
.export-button:hover,
.role-selector button:hover {
    background-color: #5a5a8b;
}

.navigation-controls button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.navigation-controls span {
    font-weight: bold;
    font-family: 'Inter', sans-serif;
}

.main-content {
    display: flex;
    width: 100%;
    margin-top: 1rem;
}

.right-column {
    flex: 1;
    padding-left: 1rem;
}

.role-selector {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.table-container {
    flex: 1;
}


.react-calendar {
    color: var(--primary-color);
    width: 100%;
    max-width: 500px;
    background-color: var(--background-color);
    border: 1px solid #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Inter', sans-serif;
    padding: 10px;
    margin: 0 auto;
}

.react-calendar__tile--active {
    background-color: var(--success-color) !important;
}

.react-calendar__tile--now {
    background: #f7f7f7;
    color: #3c3c3c;
}

.react-calendar__navigation button {
    margin: 4px;
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.react-calendar__navigation button:hover {
    background-color: var(--accent-color);
}


.record-dot {
    height: 18px;
    width: 18px;
    background-color: var(--accent-color);
    border-radius: 25%;
    display: inline-block;
    margin-left: 5px;
    font-weight: bold;
}

.selected {
    background-color: var(--accent-color) !important;
    color: #ffffff !important;
}

.unselected {
    background-color: var(--primary-color);
    color: #ffffff;
}

.record-count {
    color: #5d5d5d;
    text-align: right;
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 24px;
}

.data-table th, .data-table td {
    font-size: 12px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.navigation-controls .active {
    background-color: var(--success-color);
    color: white;
}

.highlight {
    background-color: var(--success-color);
    color: white;
}

/* Fade-up animation */
@keyframes fadeUp {
    from {
        opacity: 0;
        transform: translateY(20px);
        scale: 1.03;
    }
    to {
        opacity: 1;
        transform: translateY(0);
        scale: 1;
    }
}

.fade-in {
    animation: fadeIn 0.3s ease-in;
}

/* Term Selector Styles */
.term-selector {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 8px;
    background-color: var(--secondary-color);

    border-radius: 4px;
}

.term-selector label {
    margin-right: 10px;
    font-weight: 500;
    color: var(--text-color);
}

.term-selector select {
    padding: 8px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--background-color);
    font-size: 16px;
    color: var(--text-color);
    transition: background-color 0.3s ease, color 0.3s ease;
}

.term-selector select:focus {
    outline: none;
    border-color: var(--primary-color);
}

.term-selector select option {
    padding: 8px;
    background-color: var(--background-color);
    color: var(--text-color);
}

.pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.pagination-controls button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background-color: var(--primary-color);
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: 500;
}

.pagination-controls button:disabled {
    background-color: var(--border-color);
    cursor: not-allowed;
}

.pagination-controls span {
    font-size: 16px;
    font-weight: 500;
}

.dna-wrapper circle:nth-child(odd) {
    fill: var(--primary-color) !important; /* Bright Blue */
}

.dna-wrapper circle:nth-child(even) {
    fill: var(--accent-color) !important; /* Light Green */
}

.term-box {
    width: 170px;
    margin-bottom: 20px;
}

.term-select-text {
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 5px;
    display: block;
}

.term-sub_text {
    font-size: 10px;
    color: var(--text-color);
    margin-bottom: 5px;
}

.select-button-home {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--background-color);
    color: var(--text-color);
    font-size: 14px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.select-button-home:focus {
    outline: none;
    border-color: var(--primary-color);
}

.select-button-home option:checked {
    background-color: var(--primary-color);
    color: var(--background-color);
}

.left-column {
    display: flex;
    flex-direction: column;
    padding-right: 10px;
}

.date-container {
    color: white;
    margin-right: 15px;
}

.class-selector-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.class-and-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.class-selector {
    flex: 0.3; /* Allow class selector to take up available space */
}

.navigation-controls {
    display: flex;
    align-items: center;
}

.date-container {
    margin-right: 15px;
}

