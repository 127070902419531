@import './global-styles.css';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
}

.landing-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.hero {
    text-align: center;
    padding: 80px 0;
    background-color: var(--light-background);
    position: relative;
    overflow: visible; /* Ensure that overflow is visible */
    border-radius: 16px;
}

.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(circle at 10% 20%, rgba(144, 204, 244, 0.1) 0%, rgba(144, 204, 244, 0) 60%);
    z-index: 0;
}

.hero > * {
    position: relative;
    z-index: 1;
}

.hero h1 {
    font-size: 3.5rem;
    font-weight: 800;
    margin-bottom: 20px;
    color: var(--primary-color);
}

.hero p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.cta-button {
    display: inline-block;
    padding: 15px 30px;
    background-color: var(--secondary-color);
    color: var(--background-color);
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1rem;
    transition: all 0.3s ease;
    margin: 0 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
    background-color: #F9A3A3; /* Lighter coral */
    transform: translateY(-3px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

section {
    margin-bottom: 80px;
    padding: 60px 0;
}

h2 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 40px;
    color: var(--primary-color);
}

.features-container, .steps-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -20px;
}

.feature, .step {
    flex-basis: calc(33.333% - 40px);
    text-align: center;
    margin: 0 20px 40px;
    padding: 30px;
    border-radius: 15px;
    transition: all 0.3s ease;
    background-color: var(--background-color);
    box-shadow: 0 5px 15px rgba(124, 158, 178, 0.1);
    border: 1px solid #E0E9EF;
}

.feature:hover, .step:hover {
    transform: translateY(-5px) scale(1.03);
    box-shadow: 0 8px 20px rgba(124, 158, 178, 0.2);
}

@keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
}

.feature i {
    font-size: 3rem;
    color: var(--primary-color);
    margin-bottom: 20px;
    transition: transform 0.3s ease;
    animation: float 4s ease-in-out infinite;
}

.feature:hover i {
    transform: scale(1.1);
}

.feature h3, .step h3 {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: var(--primary-color);
    transition: color 0.3s ease;
}

.feature:hover h3, .step:hover h3 {
    color: var(--secondary-color);
}

.step-number {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: var(--accent-color);
    color: var(--text-color);
    border-radius: 50%;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 15px;
    transition: all 0.3s ease;
}

.step:hover .step-number {
    background-color: var(--secondary-color);
    color: var(--background-color);
    transform: scale(1.1);
}

.testimonials {
    background-color: var(--light-background);
    padding: 60px 0;
    border-radius: 16px;
    position: relative; /* Ensure it can contain absolutely positioned elements */
    overflow: visible;
}

blockquote {
    font-style: italic;
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto 20px;
    text-align: center;
}

cite {
    font-style: normal;
    font-weight: 600;
}

.cta {
    text-align: center;
    background-color: var(--primary-color);
    padding: 80px 0;
    color: var(--background-color);
    border-radius: 16px;
    position: relative; /* Ensure it can contain absolutely positioned elements */
    overflow: visible;
}

.cta h2 {
    color: var(--background-color);
}

footer {
    text-align: center;
    padding: 40px 0;
    background-color: var(--light-background);
    color: var(--text-color);
    border-radius: 16px;
}

footer a {
    color: var(--secondary-color);
    text-decoration: none;
    font-weight: 500;
}

.social-media {
    margin-top: 20px;
}

.social-media a {
    display: inline-block;
    margin: 0 10px;
}

.social-media img {
    width: 24px;
    transition: all 0.3s ease;
    filter: opacity(0.7);
}

.social-media img:hover {
    transform: scale(1.2);
    filter: opacity(1);
}

@media (max-width: 768px) {
    .hero h1 {
        font-size: 2.5rem;
    }

    .hero p {
        font-size: 1rem;
    }

    .feature, .step {
        flex-basis: calc(100% - 40px);
    }

    .cta-button {
        display: block;
        margin: 10px auto;
        max-width: 200px;
    }
}

@media (max-width: 480px) {
    header {
        flex-direction: column;
        text-align: center;
    }

    .tagline {
        margin-top: 10px;
    }

    .hero h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 2rem;
    }
}

.mascot {
    width: 100px; /* Set the desired size of the mascot */
    height: auto; /* Maintain aspect ratio */
    z-index: 100; /* Ensure it appears above other content */
}

.mascot.top-left {
    position: absolute;
    top: -30px; /* Adjust this value to control the vertical position */
    left: -30px; /* Adjust this value to control the horizontal position */
}

.mascot.bottom-right {
    position: absolute;
    bottom: -30px; /* Adjust this value to control the vertical position */
    right: -30px; /* Adjust this value to control the horizontal position */
}

.mascot.bottom-left {
    position: absolute;
    bottom: -30px; /* Adjust this value to control the vertical position */
    left: -30px; /* Adjust this value to control the horizontal position */
}
