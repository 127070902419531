@import './global-styles.css';

body {
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--background-color);
}

.term-manager {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.term-manager h1 {
    text-align: center;
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.button {
    padding: 8px 12px;
    margin: 4px;
    border: none;
    border-radius: 4px;
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: 500;
}

.button.primary {
    background-color: var(--success-color);
}

.button.danger {
    background-color: var(--danger-color);
}

.button:hover {
    background-color: #5a5a8b;
}

.button.primary:hover {
    background-color: #1d6219;
}

.button.danger:hover {
    background-color: #c82333;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

.input {
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
}

.term-list {
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
}

.term-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--light-background);
    margin-bottom: 10px;
}

.term-item span {
    flex-grow: 1;
}

.term-button {
    width: 100px;
    height: 24px;
    background-color: var(--success-color);
    border-radius: 6px;
    border-color: transparent;
    color: var(--background-color);
    margin-right: 4px;
}

.term-button.danger {
    width: 100px;
    height: 24px;
    background-color: var(--danger-color);
    border-radius: 6px;
    border-color: transparent;
    color: var(--background-color)
}
