@import './global-styles.css';

.behavior-graph-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px;
    max-width: 600px;
    background-color: var(--background-color);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Styling for the PieChart */
.pie-chart {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Tooltip styling */
.recharts-tooltip-wrapper {
    background-color: var(--light-background);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Legend styling */
.recharts-legend-wrapper {
    margin-top: 40px; /* Increased to push the entire legend down */
    text-align: center;
}

.recharts-legend-item {
    display: inline-block;
    margin: 0 10px;
    font-size: 14px;
    color: var(--text-color);
}

/* Label styling for Pie */
.recharts-label {
    font-size: 12px;
    font-weight: 600;
    fill: var(--text-color);
}

/* Customizing colors for each segment */
.recharts-surface .recharts-pie-sector {
    transition: all 0.3s ease-in-out;
}

.recharts-surface .recharts-pie-sector:hover {
    transform: scale(1.05);
}

.spacer {
    height: 50px;
}
