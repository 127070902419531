@import './global-styles.css';

.three-month-calendar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.react-multi-date-picker .rmdp-wrapper {
    display: flex;
    flex-direction: row;
}

.react-multi-date-picker .rmdp-calendar {
    margin: 0 10px;
}

.react-multi-date-picker .rmdp-day {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.react-multi-date-picker .record-dot {
    width: 6px;
    height: 6px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    bottom: 5px;
}

.react-multi-date-picker .rmdp-day.has-record {
    background-color: var(--secondary-color) !important;
}

.react-multi-date-picker .rmdp-day.selected {
    background-color: var(--accent-color) !important;
    color: var(--accent-color) !important;
}

.react-multi-date-picker .rmdp-day.focused {
    border: 2px solid var(--border-color) !important;
}

.rmdp-range {
    color: var(--background-color)!important;
    background-color: var(--secondary-color)!important;
}

.rmdp-week-day {
    color: var(--text-color)!important;
}

.rmdp-day.rmdp-today span {
    background-color: var(--accent-color)!important;
}

.react-multi-date-picker .rmdp-day:not(.rmdp-day-disabled):hover {
    background-color: var(--accent-color) !important;
    color: var(--secondary-color) !important;
}
