.search-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0.5rem;
}
.search-box {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
}

.search-input {
    width: 100%;
    min-height: 56px; /* Set a minimum height */
    overflow-y: hidden; /* Hide scrollbar during transition */
    transition: height 0.1s ease;
    padding: 16px 50px 16px 20px;
    border: 1px solid #d1d5db;
    border-radius: 18px;
    background: white;
    font-size: 1em;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    line-height: 1.5;
    font-family: inherit;
}

.search-input:focus {
    outline: none;
    box-shadow: 0 4px 12px -1px rgba(0, 0, 0, 0.15);
}
.search-button {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    background: #2563eb;
    border: none;
    border-radius: 36px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
}

.search-button:hover {
    background: var(--primary-color);
}

.search-button {
    background: var(--secondary-color);
}

.search-icon {
    color: var(--background-color);
    font-size: 1.2em;
    font-weight: bolder;
}

.loading-spinner {
    color: white;
    font-size: 1.2em;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.search-button:disabled {
    background-color: #93c5fd;
    cursor: not-allowed;
}
.master-context {
    background-color: #f8fafc;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    border: 1px solid #e2e8f0;
}

.master-context h2 {
    color: #1f2937;
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.context-details {
    color: #4b5563;
    font-size: 0.875rem;
}

.context-details p {
    margin: 0.5rem 0;
}

.results-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.result-card {
    background-color: white;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.result-heading {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #1f2937;
}

.metrics-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 1rem;
}

.metric {
    background-color: #f8fafc;
    padding: 0.8rem;
    border-radius: 4px;
    text-align: center;
    font-size: 0.875rem;
    color: #4b5563;
}

.result-content {
    color: #374151;
    line-height: 1.6;
    margin-bottom: 1rem;
}

.prompt-container {
    background-color: #f8fafc;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    border: 1px solid #e2e8f0;
}

.prompt-container h2 {
    color: #1f2937;
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.prompt-content {
    white-space: pre-wrap;
    font-family: monospace;
    background-color: white;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid #e2e8f0;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #4b5563;
    overflow-x: auto;
}

.filter-section {
    margin-bottom: 2rem;
    justify-content: center;
    background-color: var(--border-color);
    padding: 8px;
    border-radius: 12px;
}

.subject-buttons,
.stage-buttons {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}

.filter-button {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s;
}

.filter-button:hover {
    background-color: #f8fafc;
}

.filter-button.selected {
    background-color: #3b82f6;
    color: white;
    border-color: #3b82f6;
}

.prompt-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.prompt-controls {
    display: flex;
    gap: 0.5rem;
}

.control-button {
    padding: 0.4rem 0.8rem;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s;
}

.control-button:hover {
    background-color: #f8fafc;
}

.recent-queries {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.7rem;
    flex-direction: column;
    background: var(--light-background);
    border-radius: 16px;
    padding: 8px;
}

.recent-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.recent-title {
    font-size: 12px;
    top: 0;
}

.recent-query-button {
    padding: 0.5rem 1rem;
    border: 1px solid var(--primary-color);
    border-radius: 32px;
    background-color: rgba(0,0,0,0.1);
    cursor: pointer;
    font-size: 10px;
    transition: all 0.2s;
    color: white;
    font-weight: bolder;
}

.recent-query-button:hover {
    background-color: var(--primary-color);
}

.ai-response-container {
    background-color: #f8fafc;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    border: 1px solid #e2e8f0;
}

.ai-response-container h2 {
    color: #1f2937;
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.ai-response-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.ai-response-heading {
    font-size: 1.125rem;
    font-weight: bold;
    color: #1f2937;
    margin: 0.5rem 0;
}

.ai-response-paragraph {
    font-size: 0.875rem;
    color: #374151;
    line-height: 1.6;
}

.ai-response-list-item {
    font-size: 0.875rem;
    color: #374151;
    line-height: 1.6;
    margin-left: 1rem;
}

.files-section {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    padding: 20px;
    background: linear-gradient(145deg, #ffffff, #f5f7fa);
    border-radius: 16px;
    justify-content: center;
    border: 1px solid var(--secondary-color);
    position: relative;
}

.files-section::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50px;
    background: linear-gradient(to right, transparent, var(--background-color));
    pointer-events: none;
}
.response-files {
    display: flex;
    gap: 15px;
    margin-top: 15px;
    overflow-x: auto;
    padding: 10px 0; /* Add padding for scrollbar */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    scrollbar-width: thin; /* Firefox scrollbar */
}

/* Optional: Style the scrollbar for webkit browsers */
.response-files::-webkit-scrollbar {
    height: 8px;
}

.response-files::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.response-files::-webkit-scrollbar-thumb {
    background: #cbd5e0;
    border-radius: 4px;
}
.response-file {
    background-color: var(--light-background);
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 180px;
    border: 1px solid var(--primary-color);
}

.file-button {
    width: 100%;
    padding: 8px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 8px;
    overflow: hidden;
}

.file-actions {
    display: flex;
    gap: 8px;
}

.action-button {
    flex: 1;
    padding: 4px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: var(--secondary-color);
    cursor: pointer;
    color: white;
}

.file-name {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.action-button:hover {
    background: var(--primary-color);
    font-weight: bold;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    display: flex;
    flex-direction: column;
    height: 90vh;
}

.modal-actions {
    display: flex;
    gap: 10px;
    align-items: center;
}

.copy-buttons {
    display: flex;
    gap: 8px;
}

.copy-buttons .action-button {
    font-size: 0.75rem;
    padding: 4px 8px;
    white-space: nowrap;
    background: #2563eb;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.copy-buttons .action-button:hover {
    background: #1d4ed8;
}

.modal-header {
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
    padding: 12px 20px;
    border-bottom: 1px solid #edf2f7;
}

.modal-close {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 5px 10px;
}

.modal-body {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
}

.response-file[data-status="working"] {
    background: #f0f9ff;
    border: 1px solid #bae6fd;
}

.response-file[data-status="working"] .file-name {
    color: #0369a1;
}


.delete-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #fee2e2;
    color: #dc2626;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
    opacity: 0;
    transition: all 0.2s ease;
}

.response-file {
    position: relative;
    min-width: 180px;
}

.response-file:hover .delete-icon {
    opacity: 1;
}

.delete-icon:hover {
    background: #dc2626;
    color: white;
    transform: scale(1.1);
}

.spinner {
    width: 24px;
    height: 24px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #2563eb;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.response-file[data-status="working"] {
    background: #f0f9ff;
    border: 1px solid #bae6fd;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
}

.search-surround {
    border-radius: 16px;
    border: 1px solid var(--secondary-color);
    padding: 16px;
}

.model-toggle {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
}

.model-button {
    padding: 8px 16px;
    border: 2px solid #ddd;
    border-radius: 20px;
    background: white;
    cursor: pointer;
    transition: all 0.3s ease;
}

.model-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    vertical-align: middle;
}

.model-button.selected {
    background: #007bff;
    color: white;
    border-color: var(--primary-color);
}

.model-button:hover {
    background: #f0f0f0;
}

.model-button.selected:hover {
    background: #0056b3;
}

.formatted-content {
    font-family: 'Calibri', sans-serif;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.content-heading {
    font-size: 1rem;
    font-weight: bold;
    margin: 1em 0 0.6em;
    color: #2c3e50;
}

.content-paragraph {
    margin: 0.6em 0;
    color: #2c3e50;
    font-size: 0.75rem;
    line-height: 1.5;
}

.content-bullet {
    display: flex;
    align-items: flex-start;
    margin: 0.3em 0;
    padding-left: 1em;
    font-size: 0.75rem;
}

.bullet-marker {
    margin-right: 0.4em;
    color: #2c3e50;
}

.bullet-content {
    flex: 1;
    color: #2c3e50;
    line-height: 1.5;
}

.formatted-content strong {
    color: #1a202c;
}

.modal-body .formatted-content {
    font-family: 'Calibri', sans-serif;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: #2c3e50;
}

.modal-body .content-heading {
    font-size: 1.1rem;
    font-weight: bold;
    margin: 1em 0 0.6em;
    color: #2c3e50;
    padding-bottom: 0.3em;
    border-bottom: 2px solid #edf2f7;
}

.modal-body .content-paragraph {
    margin: 0.6em 0;
    font-size: 0.75rem;
    line-height: 1.5;
}

.modal-body .content-bullet {
    display: flex;
    align-items: flex-start;
    margin: 0.5em 0 0.5em 1em;
    font-size: 0.75rem;
    line-height: 1.5;
}

.modal-body .bullet-marker {
    margin-right: 0.75em;
    color: #4a5568;
    font-size: 1.2em;
}

.modal-body .bullet-content {
    flex: 1;
}

.modal-body strong {
    color: #1a202c;
    font-weight: 600;
}

/* Modal styling improvements */
.modal-content {
    background: white;
    border-radius: 8px;
    max-width: 900px;
    width: 90%;
    max-height: 90vh;
    margin: 20px auto;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
    padding: 1.5rem;
    border-bottom: 1px solid #edf2f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f8fafc;
    border-radius: 8px 8px 0 0;
}

.modal-body {
    padding: 2rem;
    overflow-y: auto;
    max-height: calc(90vh - 100px);
}

/* Strong text styling */
.formatted-content strong {
    color: #1a202c;
    font-weight: 600;
}

/* Spacing between sections */
.content-paragraph + .content-heading {
    margin-top: 2em;
}

.content-bullet + .content-heading {
    margin-top: 2em;
}

/* Scrollbar styling */
.modal-body::-webkit-scrollbar {
    width: 8px;
}

.modal-body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.modal-body::-webkit-scrollbar-thumb {
    background: #cbd5e0;
    border-radius: 4px;
}

.modal-body::-webkit-scrollbar-thumb:hover {
    background: #a0aec0;
}

.content-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0.6em 0;
}

.table-header {
    background: #f8fafc;
    padding: 6px 10px;
    text-align: left;
    border: 1px solid #e2e8f0;
    font-weight: bold;
}

.table-cell {
    padding: 6px 10px;
    border: 1px solid #e2e8f0;
    line-height: 1.5;
    font-size: 0.75rem;  /* Match body text size */
}

.table-row:nth-child(even) {
    background: #f8fafc;
}

.toast {
    position: fixed;
    top: 80px;
    right: 20px;
    background: var(--accent-color);
    color: white;
    padding: 12px 24px;
    border-radius: 8px;
    z-index: 1000;
    animation: slideIn 0.3s ease-out;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.loading-message {
    font-size: 10px;
    color: #666;
    text-align: center;
    margin-top: 4px;
}

.scroll-indicator {
    position: absolute;
    right: 10px;
    bottom: -5%;
    transform: translateY(-50%);
    color: var(--secondary-color);
    animation: bounce 1s infinite;
    z-index: 1;
}

@keyframes bounce {
    0%, 100% { transform: translateX(0); }
    50% { transform: translateX(3px); }
}

.template-selector {
    margin-left: 1rem;
    position: relative;
    display: inline-block; /* Ensure proper sizing for absolute positioning */
}

.template-select {
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid #ddd;
    background-color: white;
    font-size: 14px;
    min-width: 120px;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 16px;
    padding-right: 32px;
    transition: all 0.2s ease-out;
}

.template-select:hover {
    border-color: #999;
}

.template-select:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}

.template-options {
    position: absolute;
    top: calc(100% + 4px); /* Include margin in the initial position */
    left: 50%;
    transform: translate(-50%, -10px);
    background: white;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    min-width: 180px;
    z-index: 100;
    opacity: 0;
    visibility: hidden; /* Hide completely when not shown */
    transition: opacity 0.2s ease-out, transform 0.2s ease-out, visibility 0.2s ease-out;
}

.template-options.show {
    opacity: 1;
    transform: translate(-50%, 0);
    visibility: visible;
}

.template-option {
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-size: 14px;
}

.template-option:hover {
    background-color: #f8fafc;
}

.template-option:first-child {
    border-radius: 6px 6px 0 0;
}

.template-option:last-child {
    border-radius: 0 0 6px 6px;
}

.model-and-template-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

/* Outcome Options Dropdown Styling */
.outcome-options-dropdown {
    position: relative;
    margin-left: 1rem;
}

.outcome-options-trigger {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid #ddd;
    background-color: white;
    font-size: 14px;
    cursor: pointer;
    min-width: 120px;
}

.trigger-text {
    flex: 1;
    text-align: left;
}

.trigger-indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #cbd5e0;
}

.trigger-indicator.some-on {
    background-color: var(--primary-color);
}

.trigger-arrow {
    font-size: 10px;
    color: #666;
}

.outcome-options-menu {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 4px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    padding: 0.75rem;
    min-width: 240px;
    z-index: 100;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    pointer-events: none;
}

.outcome-options-menu.show {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

.options-group {
    padding: 0.5rem 0;
}

.options-group h4 {
    font-size: 0.75rem;
    color: var(--primary-color);
    margin: 0 0 0.5rem 0;
    font-weight: 600;
}

.toggle-option {
    display: flex;
    align-items: center;
    padding: 0.25rem 0;
    cursor: pointer;
}

.toggle-option input[type="checkbox"] {
    margin-right: 0.5rem;
    cursor: pointer;
}

.toggle-label {
    font-size: 0.75rem;
    color: var(--text-color);
}

.all-toggle {
    padding-bottom: 0.5rem;
    font-weight: 500;
}

.options-divider {
    height: 1px;
    background: #e2e8f0;
    margin: 0.5rem 0;
}

