.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
    animation: fadeUp 0.5s ease-in-out;
}

@keyframes fadeUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.intro-text {
    text-align: center;
    margin-bottom: 40px;
    color: var(--text-color);
    background-color: var(--light-background);
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    max-width: 800px;
}

.intro-text h1 {
    margin-bottom: 20px;
    font-size: 36px;
    color: var(--primary-color);
    letter-spacing: -0.5px;
}

.intro-text p {
    font-size: 18px;
    line-height: 1.6;
    color: var(--text-color);
}

.contact-form {
    max-width: 600px;
    width: 100%;
    margin-top: 20px;
    padding: 40px;
    background-color: var(--background-color);
    border-radius: 12px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.contact-form:hover {
    transform: translateY(-5px);
}

.contact-form h2 {
    margin-bottom: 30px;
    font-size: 28px;
    color: var(--primary-color);
    text-align: center;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    border: 2px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--light-background);
    color: var(--text-color);
    font-size: 16px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(var(--primary-color-rgb), 0.2);
}

.contact-form button {
    width: 100%;
    padding: 15px;
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.1s ease;
}

.contact-form button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.contact-form button:not(:disabled):hover {
    background-color: var(--accent-color);
    transform: translateY(-2px);
}

.contact-form button:not(:disabled):active {
    transform: translateY(0);
}

.contact-form-success {
    max-width: 600px;
    margin: 40px auto;
    padding: 40px;
    background-color: var(--light-background);
    border-radius: 12px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
    color: var(--primary-color);
    font-size: 20px;
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Styles for the platform toggle */
.platform-toggle {
    display: flex;
    justify-content: center; /* Center the toggle within the container */
    margin: 20px 0 30px; /* Adjust spacing */
}

.platform-toggle label {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-background);
    border: 2px solid var(--border-color);
    border-radius: 8px;
    padding: 10px 20px;
    margin: 0 10px; /* Space between the buttons */
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
    color: var(--text-color); /* Default text color */
}

/* Hide the original radio inputs */
.platform-toggle input {
    display: none;
}

/* Styles for when hovering over a label */
.platform-toggle label:hover {
    background-color: var(--accent-color);
}

/* Styles for when a radio input is selected */
.platform-toggle input:checked + label {
    background-color: var(--primary-color); /* Highlight the selected button */
    border-color: var(--primary-color);
    color: var(--background-color); /* Text color when active */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Optional shadow effect */
}

/* Slightly dim unselected buttons */
.platform-toggle input:not(:checked) + label {
    opacity: 0.8; /* Reduced opacity for non-selected labels */
}

@media (max-width: 768px) {
    .intro-text,
    .contact-form {
        padding: 30px;
    }

    .intro-text h1 {
        font-size: 28px;
    }

    .intro-text p,
    .contact-form input,
    .contact-form textarea,
    .contact-form button {
        font-size: 16px;
    }

    /* Responsive styles for platform toggle */
    .platform-toggle label {
        padding: 10px;
        margin: 0 5px;
        font-size: 14px; /* Smaller text size for mobile */
    }
}

