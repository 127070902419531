.messaging-container {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.messaging-layout {
    display: flex;
    gap: 20px;
}

.editor-wrapper {
    flex: 1;
}

.form-wrapper {
    flex: 1;
    margin-bottom: 20px;
}

.notification-form {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.form-group {
    flex: 1;
    margin-bottom: 20px;
}

.form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    background-color: white;
    appearance: none;
}

.form-actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
}
.calendar-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.events-list {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}
.survey-choice {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.survey-choice {
    transition: all 0.3s ease;
}

.survey-choice:hover {
    background-color: #f9f9f9;
}

.choice-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.form-group img {
    max-width: 100px;
    max-height: 100px;
    border-radius: 4px;
    margin-top: 10px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: #333;
}

.messaging-layout {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.editor-wrapper,
.form-wrapper {
    flex: 1;
    min-width: 300px;
}

.button {
    background: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    transition: background 0.3s;
    margin: 5px 0;
}

.button:hover {
    background: #0056b3;
}

.form-group input,
.form-group textarea,
.form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

.form-group textarea {
    min-height: 120px;
    resize: vertical;
}.send-button {
    background: #007bff;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    transition: background 0.3s;
}

.send-button:hover {
    background: #0056b3;
}

.calendar-container {
    margin-bottom: 20px;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.react-calendar {
    border: none;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;
}

.react-calendar__tile {
    background: #f9f9f9;
    border: 1px solid #ddd;
    transition: background 0.3s;
}

.react-calendar__tile--active {
    background: #007bff;
    color: white;
}

.react-calendar__tile--now {
    background: #ffeb3b;
}

.react-calendar__tile:hover {
    background: #e0e0e0;
}

.events-list {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.event-item {
    margin-bottom: 10px;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.event-item h5 {
    margin: 0 0 5px;
    font-size: 16px;
    color: #007bff;
}

.event-item p {
    margin: 0 0 5px;
    color: #555;
    font-size: 14px;
}

.event-item button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 12px;
    margin-right: 5px;
    transition: color 0.3s;
}

.event-item button:hover {
    color: #0056b3;
}

.button {
    background: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    transition: background 0.3s;
    margin: 5px 0;
}

.button:hover {
    background: #0056b3;
}